<template>
	<div class="visa-index">
		<div class="index-backg">
			<div class="index-backgc">
				<div class="index-img">
					<!-- 头部背景图片 -->
					<img src="../../assets/visaindex.png" class="index-imga">
				</div>
				<div class="index-backgd">
					<div class="index-backga">
						<!-- <el-input v-model="input" placeholder="输入要办理签证的国家或地区名称" class="index-inp"></el-input> -->
						<el-autocomplete style="width:100%" class="inline-input"
							v-model="DestinationCountryVal" :fetch-suggestions="queryCitySearch"
							placeholder="输入要办理签证的国家或地区名称" :trigger-on-focus="false"
							@select="handleSelectCity"></el-autocomplete>
						<div class="index-iconfa">
							<span class="iconfont icon-chalv-fangdajing"></span>
						</div>
					</div>
					<div class="index-backge">
						<div class="index-backgm" @click="bindJumpListByCode('US')">美国</div>
						<div class="index-backgm" @click="bindJumpListByCode('CA')">加拿大</div>
						<div class="index-backgm" @click="bindJumpListByCode('SG')">新加坡</div>
						<div class="index-backgm" @click="bindJumpListByCode('AU')">澳大利亚</div>
					</div>
				</div>
			</div>

			<div class="index-multilayer">
				<div class="index-multilayera" v-show="false">
					<!-- 当秀推荐头 -->
					<div class="index-multilayerb" @click="ban">
						<div class="index-multilayerc">
							<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index1.jpg"
								class="index-multilayerd">
						</div>
						<div class="index-multilayere">
							<div class="index-multilayeree">当秀推荐</div>
							<div class="index-multilayereb">
								<div class="index-multilayereba">美国</div>
								<div class="index-multilayereba">加拿大</div>
								<div class="index-multilayereba">新加坡</div>
								<div class="">澳大利亚</div>
							</div>
						</div>
						<div class="">
							<span class="iconfont icon-chalv-xiangzuo"></span>
						</div>
					</div>
					<div class="index-multilayerb" @click="con">
						<div class="index-multilayerc">
							<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index2.png"
								class="index-multilayerd">
						</div>
						<div class="index-multilayere">
							<div class="index-multilayeree">当秀推荐</div>
							<div class="index-multilayereb">
								<div class="index-multilayereba">美国</div>
								<div class="index-multilayereba">加拿大</div>
								<div class="index-multilayereba">新加坡</div>
								<div class="">澳大利亚</div>
							</div>
						</div>
						<div class="">
							<span class="iconfont icon-chalv-xiangzuo"></span>
						</div>
					</div>
					<div class="index-multilayerb" @click="bok">
						<div class="index-multilayerc">
							<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index3.png"
								class="index-multilayerd">
						</div>
						<div class="index-multilayere">
							<div class="index-multilayeree">当秀推荐</div>
							<div class="index-multilayereb">
								<div class="index-multilayereba">美国</div>
								<div class="index-multilayereba">加拿大</div>
								<div class="index-multilayereba">新加坡</div>
								<div class="">澳大利亚</div>
							</div>
						</div>
						<div class="">
							<span class="iconfont icon-chalv-xiangzuo"></span>
						</div>
					</div>
					<div class="index-multilayerb">
						<div class="index-multilayerc">
							<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index4.png"
								class="index-multilayerd">
						</div>
						<div class="index-multilayere">
							<div class="index-multilayeree">当秀推荐</div>
							<div class="index-multilayereb">
								<div class="index-multilayereba">美国</div>
								<div class="index-multilayereba">加拿大</div>
								<div class="index-multilayereba">新加坡</div>
								<div class="">澳大利亚</div>
							</div>
						</div>
						<div class="">
							<span class="iconfont icon-chalv-xiangzuo"></span>
						</div>
					</div>
					<div class="index-multilayerb">
						<div class="index-multilayerc">
							<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index5.png"
								class="index-multilayerd">
						</div>
						<div class="index-multilayere">
							<div class="index-multilayeree">当秀推荐</div>
							<div class="index-multilayereb">
								<div class="index-multilayereba">美国</div>
								<div class="index-multilayereba">加拿大</div>
								<div class="index-multilayereba">新加坡</div>
								<div class="">澳大利亚</div>
							</div>
						</div>
						<div class="">
							<span class="iconfont icon-chalv-xiangzuo"></span>
						</div>
					</div>
					<div class="index-multilayerbp">
						<div class="index-multilayerc">
							<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index6.png"
								class="index-multilayerd">
						</div>
						<div class="index-multilayere">
							<div class="index-multilayeree">当秀推荐</div>
							<div class="index-multilayereb">
								<div class="index-multilayereba">美国</div>
								<div class="index-multilayereba">加拿大</div>
								<div class="index-multilayereba">新加坡</div>
								<div class="">澳大利亚</div>
							</div>
						</div>
						<div class="">
							<span class="iconfont icon-chalv-xiangzuo"></span>
						</div>
					</div>
					<!-- 当秀推荐尾 -->
				</div>

				<div class="">
					<!-- 推荐国家头 -->
					<div class="index-tuijian">
						<div class="index-recommenz">推荐国家</div>
						<div class="index-tuijian">
							<div class="index-tuijian" v-for="(item,index) in visaProvinces" :key="index">
								<div class="index-tuijiana" :class="[NavIdx==index?'index-tuijianb':'']"
									@click="bindChangeNav(index)">{{item.Name}}</div>
								<!-- <div class="index-tuijianc">{{item.Name}}</div> -->
							</div>
						</div>
					</div>
					<div class="index-recommenim">
						<div class="index-recommend" v-for="(item,index) in HotVisaList" :key="index"
							@click="bindJumpList(item)">
							<div class="index-recommenda">
								<img :src="item.CountryImg" class="index-recommendb">
							</div>
							<div class="index-recommene">
								<div class="">
									{{item.CountryName}}
								</div>
								<div class="index-recommenea">
									<div class="">¥</div>
									<div class="">{{item.MinPrice}}</div>
									<div class="">起</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 推荐国尾 -->
				</div>
			</div>

			<div class="" v-show="false">
				<div class="index-sell">
					<!-- 本周热卖头 -->
					<div class="index-sella">
						<div class="index-sellaa">本周热卖</div>
						<div class="index-sellab">
							<span class="iconfont icon-change index-sellac"></span>
							<div class="">换一批</div>
						</div>
					</div>
					<div class="index-sellb">
						<div class="index-sellba">
							<div class="index-sellbb" v-for="item in [1,2,3,4]" :key="item">
								<div class="index-sellbc">
									<img src="../../assets/air.png" class="index-sellbd">
								</div>
								<div class="index-sellbe">
									<div class="">
										美国旅游签证（多次）
									</div>
									<div class="index-sellbf">
										<div class="">¥</div>
										<div class="">200</div>
										<div class="">起</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 本周热卖尾 -->
			</div>

			<div class="">
				<div class="index-flow">
					<div class="index-flowa">
						<div>办理流程</div>
					</div>
					<div class="index-flowb">
						<div class="index-flowt">
							<div class="iconfonta">
								<span class="iconfont iconfontb icon-xuanzeqianzheng"></span>
							</div>
							<div class="index-flowtext">1、选择签证</div>
						</div>
						<div class="index-flowdiana">
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
						</div>
						<div class="index-flowt">
							<div class="iconfonta">
								<span class="iconfont iconfontb icon-xiadanzhifu"></span>
							</div>
							<div class="index-flowtext">2、下单支付</div>
						</div>
						<div class="index-flowdiana">
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
						</div>
						<div class="index-flowt">
							<div class="iconfonta">
								<span class="iconfont iconfontb icon-ziliaoshangchuan"></span>
							</div>
							<div class="index-flowtext">3、资料上传</div>
						</div>
						<div class="index-flowdiana">
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
						</div>
						<div class="index-flowt">
							<div class="iconfonta">
								<span class="iconfont iconfontb icon-zhuanrenyiduiyifuwu"></span>
							</div>
							<div class="index-flowtext">4、专人一对一服务</div>
						</div>
						<div class="index-flowdiana">
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
						</div>
						<div class="index-flowt">
							<div class="iconfonta">
								<span class="iconfont iconfontb icon-ziliaoshenhebingsongqian"></span>
							</div>
							<div class="index-flowtext">5、资料审核并送签</div>
						</div>
						<div class="index-flowdiana">
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
							<div class="index-flowdian"></div>
						</div>
						<div class="index-flowt">
							<div class="iconfonta">
								<span class="iconfont iconfontb icon-chuqianbingpeisong"></span>
							</div>
							<div class="index-flowtext">6、出签并配送</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	//接口
	var destApi = require('@/api/destApi.js');
	var visaApi = require('@/api/visaApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	var that;

	export default {
		data() {
			return {
				input: '',
				hotvisa: '',
				NavIdx: 0,
				visaProvinces: [{
						Code: '',
						Name: '热门'
					},
					{
						Code: 'YA',
						Name: '亚洲'
					},
					{
						Code: 'AO',
						Name: '欧洲'
					},
					{
						Code: 'BM',
						Name: '美洲'
					},
					{
						Code: 'DY',
						Name: '大洋洲'
					},
					// {
					// 	Code: 'FZ',
					// 	Name: '非洲'
					// }
				],
								
				HotVisaList: null,
				VisaNavs: null,
				VisaList: null,
				DestinationCountryVal: '',
				CountryCode:'',
				ContinentCode:''
			}
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			GetHotVisaList();
		},
		methods: {
			//推荐切换
			bindChangeNav: function(e) {				
				that.NavIdx = e;
				that.ContinentCode=that.visaProvinces[e].Code;
				GetHotVisaList();
				// that.list=index
			},
			//搜索国家			
			queryCitySearch(queryString, cb) {
				var parm = {
					keyword: queryString,
					isCountry: true
				};
				destApi.GetCityList(parm, function(result) {
					// console.log(result);;
					if (result.code == 0) {
						var allCityList = result.data.allCityList;
						cb(allCityList);
						// console.log(that.restaurants);
					} else {
						cb([]);
					}
				});
			
				// cb(results);
			},
			handleSelectCity(item) {
				console.log(item);
				that.DestinationCountryVal = item.value;
				that.CountryCode = item.code;
				
				that.$router.push({
					path: '/Visa/list',
					query: {
						CountryCode: that.CountryCode
					}
				})
			},
			//跳转列表
			bindJumpList: function(e) {
				console.log(e);
				that.$router.push({
					path: '/Visa/list',
					query: {
						CountryCode: e.CountryCode
					}
				})
			},
			bindJumpListByCode:function(e){
				that.$router.push({
					path: '/Visa/list',
					query: {
						CountryCode: e
					}
				})
			},
			ban(e) {
				this.$router.push({
					path: '/Visa/list'
				})
			},
			bok(e) {
				this.$router.push({
					path: '/Visa/booking'
				})
			},
			con(e) {
				this.$router.push({
					path: '/Visa/confirm'
				})
			}
		}
	}
	//获取热门签证
	function GetHotVisaList() {
		var parm = {
			Continent: that.ContinentCode, //洲代码
		};
		visaApi.GetHotVisaList(parm, function(result) {
			if (result.code == 0) {
				that.HotVisaList = result.data.HotVisaList
			}
		})
	}
</script>

<style lang="scss">
	@import '../../style/visa/index.scss';
</style>
